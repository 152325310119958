/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Custom Player Additions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

.fade {
    opacity: 1;

    &:not(.show) {
        transition: opacity 0.15s linear;
        opacity: 0;
    }
}

.slow-fade {
    opacity: 1;

    &:not(.show) {
        transition: opacity 0.2s linear;
        opacity: 0;
    }
}


/* Big Play Icon */
.big-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    transition: transform 0.15s linear;
    z-index: 10;
    background-color: var(--big-play-button-bg);

    &:hover {
        background-color: var(--big-play-button-bg-hover);
        transform: scale(1.1) translate(-46%, -46%);
        transition: transform 0.2s linear, opacity 0.2s linear;
        opacity: 1;
        cursor: default;

        &:not(.fade, .slow-fade) {
            /*opacity: 0.9;*/
            cursor: pointer;
        }
    }
}

.big-play-icon-circle {
    border-radius: 100px;
}

.big-play-icon-rounded {
    border-radius:  20px;
}

.big-play-icon-square {
    border-radius: 4px;
}


.big-play-icon-small {
    width: 50px;
    height: 50px;
}

.big-play-icon-medium {
    width: 80px;
    height: 80px;
}

.big-play-icon-large {
    width: 100px;
    height: 100px;
}


.player-radius {
    border-radius: var(--video-border-radius, 16px);
}

.player-radius-small {
    border-radius: var(--video-border-radius-small, 12px);
}

.player-radius-xs {
    border-radius: var(--video-border-radius-xs, 8px);
}

/* Youtube Hack to deal with black bars on the top/bottom */
.youtube-zoom {
    margin-top: -2px;
    margin-left: -2px;
    margin-bottom: -2px;
    margin-right: -2px;
    height: 100.75%;
    width: 100.75%;
}

.vds-time-slider {
    --thumb-size: 12px;
    height: 24px;
}

.vds-video-layout .vds-controls-time-group {
    margin-bottom: -12px;
    padding-left: 4px;
    padding-right: 4px;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-time-group-small {
    margin-bottom: -8px;
    /*border: 2px solid orange;*/
}

.vds-controls-main-group-small {
    margin-bottom: 0;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-top-group {
    background: linear-gradient(to top, transparent, rgb(0 0 0 / 0.5));
    height: 70px;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-top-group-small {
    background: linear-gradient(to top, transparent, rgb(0 0 0 / 0.5));
    height: 50px;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-bottom-group {
    background: linear-gradient(to bottom, transparent, rgb(0 0 0 / 0.7));
}

.logo-small {
    margin: 0 4px 0 4px;
    height: 16px;
    /*border: 2px solid orange;*/
}

.logo-medium {
    margin: 0 4px 0 4px;
    height: 32px;
    /*border: 2px solid orange;*/
}

.logo-large {
    margin: 0 4px 0 4px;
    height: 40px;
}

/*Caption Language Switcher Placement*/
.language-switcher, .language-switcher-small {
    color: white;
    position: absolute;
    left: 50%;
    padding: 0;
    transform: translateX(-50%);
    display: inline-flex !important;
    align-items: center !important;
    flex-direction: column;
    /*border: 2px solid orange;*/
}

.language-switcher {
    margin-top: -12px;
}

.language-switcher-small {
    margin-top: 0;
}

.language-text {
    font-weight: 300;
    font-size: 0.75rem;
}

.language-text-small {
    font-weight: 300;
    font-size: 0.75rem;
    margin-bottom: -6px;
}

.language-switcher-dropdown {
    height: auto;
    width: auto;
    margin-top: 12px;
    padding: 0 10px;
    border-width: 2px;
    background-color: var(--language-switcher-button-bg);
    border-radius: 5px;
}

.language-switcher-dropdown-small {
    height: auto;
    width: auto;
    margin-top: 0;
    padding: 0 10px;
    border-width: 2px;
    background-color: var(--language-switcher-button-bg);
    border-radius: 5px;
    font-size: 0.825rem;
}

/* Flags */
.flag-container {
    align-items: center !important;
    display: flex !important;
}


.flag {
    height: auto !important;
    font-size: 2rem;
    line-height: 2em;
    border-radius: 3px;
    margin: 0 4px;
}

.flag-small {
    height: auto !important;
    font-size: 1.25rem;
    line-height: 1.25em;
    border-radius: 2px;
    margin: 0 2px;
}

.flag-selected {
    box-shadow: 0 0 7px var(--video-brand);
    opacity: 1;
}

.flag-not-selected {
    opacity: 0.5;
}

:where(.vds-language-menu) {
    min-width: 250px;
}

.vds-radio:hover {
    background-color: rgb(217, 217, 217, 0.5);
}

:where(.vds-radio[aria-checked='true']) {
    padding: 0;
}

/* Hide image if the src is empty, stop the broken image sign from showing momentarily during load */
.vds-poster:not([src]) {
  visibility: hidden;
}

/* Hide image if the src is empty */
.vds-poster[src=''] {
  visibility: hidden;
}
